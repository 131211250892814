<template>
  <div class="container-fluid">

    <div class="row d-block d-xl-none text-center mt-3">
      <img class="img-fluid" src="../assets/images/logo/logo-crm-dark.png" alt style="height: 50px;" />
    </div>


    <div class="row">
      <div class="col-xl-8 p-3 p-xl-5 slider-wrapper" style="overflow: hidden">



        <Slider animation="fade" width="100%" height="100%" :indicators="false" :autoplay="false"
          v-if="medias.length > 0" style="border-radius: 8px">
          <Slider-item v-for="media in medias" :key="media">

            <a v-if="media.includes('.mp4') === false" :href="media" data-lightbox="medias">
              <img :src="media" style="width: 100%; height: 100%; object-fit: cover;" />
            </a>

            <video v-if="media.includes('.mp4') === true" style="width: 100%; height: 100%; object-fit: cover;"
              preload="metadata" controls>
              <source :src="media" type="video/mp4">
              Seu navegador não suporta o elemento de vídeo.
            </video>


          </Slider-item>
        </Slider>


        <!-- <agile v-if="medias.length > 0" :options="{
          dots: false
        }">
          <div class="slide" v-for="media in medias" style="width: 100%; height: 100%;">
            <img :src="media" style="width: 100%; height: 100%; object-fit: cover;" />
          </div>
        </agile> -->




      </div>
      <div class="col-xl-4 py-5 pr-0 details-wrapper" style="overflow: hidden;">

        <div class="pr-5" style="width: 100%; height: 100%; overflow-y: scroll; overflow-x: hidden;">

          <img class="img-fluid d-none d-xl-block" src="../assets/images/logo/logo-crm-dark.png" alt
            style="height: 60px;" />


          <p class="mt-0 mt-xl-5 mb-0" style="font-weight: 500;">
            {{
            (post.post_meta['tamanho'] ? post.post_meta['tamanho'] : "") + ' pés - ' +
            (post.post_meta['ano'] ? post.post_meta['ano'] : "")
          }}</p>

          <h1 class="mt-0">{{ (post.post_meta['fabricante'] ? post.post_meta['fabricante'] : "") }} {{
            (post.post_meta['modelo'] ? post.post_meta['modelo'] : "") }}</h1>


          <h4 class="mt-0" style="color: #1d4790" v-if="post.post_meta['valor']">R$ {{
            parseFloat(post.post_meta['valor']).toLocaleString('pt-BR')
          }}
          </h4>

          <h6 class="mt-4">Motorização</h6>

          <div class="row mt-3">

            <div class="col-xl-3 pb-2 mt-1" v-if="post.post_meta['motorizacao-marca']">
              <small><strong>Marca</strong></small>
              <p class="mb-0">{{ post.post_meta['motorizacao-marca'] }}</p>
            </div>
            <div class="col-xl-3 pb-2 mt-1" v-if="post.post_meta['motorizacao-modelo-potencia']">
              <small><strong>Modelo / Potência</strong></small>
              <p class="mb-0">{{ post.post_meta['motorizacao-modelo-potencia'] }}</p>
            </div>
            <div class="col-xl-3 pb-2 mt-1" v-if="post.post_meta['motorizacao-quantidade']">
              <small><strong>Quantidade</strong></small>
              <p class="mb-0">{{ post.post_meta['motorizacao-quantidade'] }}</p>
            </div>
            <div class="col-xl-3 pb-2 mt-1" v-if="post.post_meta['motorizacao-horas']">
              <small><strong>Horas</strong></small>
              <p class="mb-0">{{ post.post_meta['motorizacao-horas'] }}</p>
            </div>
            <div class="col-xl-3 pb-2 mt-1" v-if="post.post_meta['motorizacao-combustivel']">
              <small><strong>Combustível</strong></small>
              <p class="mb-0">{{ post.post_meta['motorizacao-combustivel'] }}</p>
            </div>

            <div class="col-xl-3 pb-2 mt-1" v-if="post.post_meta['motorizacao-superficie']">
              <small><strong>Superfície</strong></small>
              <p class="mb-0">Sim</p>
            </div>
            <div class="col-xl-3 pb-2 mt-1" v-if="post.post_meta['motorizacao-ips']">
              <small><strong>IPS</strong></small>
              <p class="mb-0">Sim</p>
            </div>
            <div class="col-xl-3 pb-2 mt-1" v-if="post.post_meta['motorizacao-pe-de-galinha']">
              <small><strong>Pé-de-Galinha</strong></small>
              <p class="mb-0">Sim</p>
            </div>
            <div class="col-xl-3 pb-2 mt-1" v-if="post.post_meta['motorizacao-popa']">
              <small><strong>Popa</strong></small>
              <p class="mb-0">Sim</p>
            </div>
            <div class="col-xl-3 pb-2 mt-1" v-if="post.post_meta['motorizacao-rabeta']">
              <small><strong>Rabeta</strong></small>
              <p class="mb-0">Sim {{ post.post_meta['motorizacao-rabeta-text'] ? (" - " +
            post.post_meta['motorizacao-rabeta-text']) : ""
                }}</p>
            </div>

          </div>




          <h6 class="mt-4">Características e Equipamentos</h6>
          <div class="row mt-3 mb-5">
            <div class="col-xl-12 pb-2 mt-1" v-if="features_text">
              <p class="mb-0">{{ features_text }}</p>
            </div>
          </div>


          <h6 class="mt-4" v-if="post.post_meta['observacoes_publicas']">Informações Extras</h6>
          <div class="row mt-3 mb-5" v-if="post.post_meta['observacoes_publicas']">
            <div class="col-xl-12 pb-2 mt-1">
              <p class="mb-0">{{ post.post_meta['observacoes_publicas'] }}</p>
            </div>
          </div>







        </div>

      </div>
    </div>
  </div>
</template>


<script>

import { Slider, SliderItem } from 'vue-easy-slider'
import 'lightbox2/dist/css/lightbox.min.css';
import 'lightbox2/dist/js/lightbox-plus-jquery.min.js';

export default {

  components: {
    Slider,
    SliderItem,
  },
  data() {
    return {
      post: post_array,
      medias: [],
      features_text: null,
      features: [
        { name: 'feat-antena-tv', title: 'Antena TV', kind: 'checkbox' },
        { name: 'feat-ar-condicionado', title: 'Ar Condicionado', kind: 'checkbox' },
        { name: 'feat-boiler', title: 'Boiler', kind: 'checkbox' },
        { name: 'feat-bowthruster', title: 'Bowthruster', kind: 'checkbox' },
        { name: 'feat-bussola', title: 'Bússola', kind: 'checkbox' },
        { name: 'feat-capa', title: 'Capa', kind: 'checkbox' },
        { name: 'feat-capota', title: 'Capota', kind: 'checkbox' },
        { name: 'feat-carregador-de-baterias', title: 'Carregador de Baterias', kind: 'checkbox' },
        { name: 'feat-carreta', title: 'Carreta', kind: 'checkbox' },
        { name: 'feat-churrasqueira', title: 'Churrasqueira', kind: 'checkbox' },
        { name: 'feat-chuveiro-de-popa', title: 'Chuveiro de Popa', kind: 'checkbox' },
        { name: 'feat-cristaleira', title: 'Cristaleira', kind: 'checkbox' },
        { name: 'feat-comando-eletronico', title: 'Comando Eletrônico', kind: 'checkbox' },
        { name: 'feat-dessalinizador', title: 'Dessalinizador', kind: 'checkbox' },
        { name: 'feat-direcao-hidraulica', title: 'Direção Hidráulica', kind: 'checkbox' },
        { name: 'feat-dvd', title: 'DVD', kind: 'checkbox' },

        { name: 'feat-estabilizador', title: 'Estabilizador', kind: 'checkbox' },
        { name: 'feat-farois', title: 'Faróis', kind: 'checkbox' },
        { name: 'feat-farol-robotizado', title: 'Farol Robotizado', kind: 'checkbox' },
        { name: 'feat-fechamento-completo', title: 'Fechamento Completo', kind: 'checkbox' },
        { name: 'feat-flaps-hidraulicos', title: 'Flaps Hidráulicos', kind: 'checkbox' },
        { name: 'feat-fogao-eletrico', title: 'Fogão Elétrico', kind: 'checkbox' },
        { name: 'feat-freezer', title: 'Freezer', kind: 'checkbox' },
        { name: 'feat-geladeira', title: 'Geladeira', kind: 'checkbox' },
        { name: 'feat-geleira', title: 'Geleira', kind: 'checkbox' },
        { name: 'feat-gerador', title: 'Gerador', kind: 'checkbox' },
        { name: 'feat-gerador-kva', title: 'Gerador KVA', kind: 'text' },
        { name: 'feat-gerador-horas', title: 'Gerador Horas', kind: 'number' },
        { name: 'feat-gps', title: 'GPS', kind: 'checkbox' },
        { name: 'feat-gps-fly', title: 'GPS Fly', kind: 'checkbox' },
        { name: 'feat-guincho-eletrico', title: 'Guincho Elétrico', kind: 'checkbox' },
        { name: 'feat-home-theater', title: 'Home Theater', kind: 'checkbox' },
        { name: 'feat-icemaker', title: 'Icemaker', kind: 'checkbox' },

        { name: 'feat-iluminacao-subaquatica', title: 'Iluminação Subaquática', kind: 'checkbox' },
        { name: 'feat-inversor', title: 'Inversor', kind: 'checkbox' },
        { name: 'feat-joystick', title: 'Joystick', kind: 'checkbox' },
        { name: 'feat-plataforma-submergivel', title: 'Plataforma Submergível', kind: 'checkbox' },
        { name: 'feat-microondas', title: 'Microondas', kind: 'checkbox' },
        { name: 'feat-passarela-hidraulica', title: 'Passarela Hidráulica', kind: 'checkbox' },
        { name: 'feat-piloto-automatico', title: 'Piloto Automático', kind: 'checkbox' },
        { name: 'feat-plotter', title: 'Plotter', kind: 'checkbox' },
        { name: 'feat-projetor-telao', title: 'Projetor / Telão', kind: 'checkbox' },
        { name: 'feat-radar', title: 'Radar', kind: 'checkbox' },
        { name: 'feat-radio-vhf', title: 'Rádio VHF', kind: 'checkbox' },
        { name: 'feat-radio-vhf-fly', title: 'Rádio VHF Fly', kind: 'checkbox' },
        { name: 'feat-sky', title: 'SKY', kind: 'checkbox' },
        { name: 'feat-solario-de-popa', title: 'Solário de Popa', kind: 'checkbox' },
        { name: 'feat-solario-de-proa', title: 'Solário de Proa', kind: 'checkbox' },
        { name: 'feat-som', title: 'Som', kind: 'checkbox' },

        { name: 'feat-sonda', title: 'Sonda', kind: 'checkbox' },
        { name: 'feat-strobolight', title: 'Strobolight', kind: 'checkbox' },
        { name: 'feat-tapete-emborrachado', title: 'Tapete Emborrachado', kind: 'checkbox' },
        { name: 'feat-targa', title: 'Targa', kind: 'checkbox' },
        { name: 'feat-teka-patamar-de-popa', title: 'Teka Patamar de Popa', kind: 'checkbox' },
        { name: 'feat-teka-praca-de-popa', title: 'Teka Praça de Popa', kind: 'checkbox' },
        { name: 'feat-teka-cockpit', title: 'Teka Cockpit', kind: 'checkbox' },
        { name: 'feat-teka-passadico', title: 'Teka Passadiço', kind: 'checkbox' },
        { name: 'feat-teka-proa', title: 'Teka Proa', kind: 'checkbox' },
        { name: 'feat-televisao', title: 'Televisão', kind: 'checkbox' },
        { name: 'feat-toldo', title: 'Toldo', kind: 'checkbox' },
        { name: 'feat-tomada-de-cais', title: 'Tomada de Cais', kind: 'checkbox' },
        { name: 'feat-turco-na-popa', title: 'Turco na Popa', kind: 'checkbox' },
        { name: 'feat-ventilador', title: 'Ventilador', kind: 'checkbox' },
        { name: 'feat-wc-eletrico', title: 'WC Elétrico', kind: 'checkbox' },
        { name: 'feat-wc-manual', title: 'WC Manual', kind: 'checkbox' },

        { name: 'feat-defensa', title: 'Defensa', kind: 'checkbox' },
        { name: 'feat-extintor', title: 'Extintor', kind: 'checkbox' },

        { name: 'feat-quartos', title: 'Quartos', kind: 'number' },
        { name: 'feat-banheiros', title: 'Banheiros', kind: 'number' },
      ],

    };
  },
  computed: {

  },
  mounted() {



    if (notfound) {
      this.$router.push({ path: `/404` })
      return
    }


    if (post_array && post_array.post_meta['medias']) {
      this.medias = post_array.post_meta['medias'].split(";");
    }


    var array_feats = [];
    if (post_array.post_meta) {

      this.features.forEach((item, index) => {

        if (post_array.post_meta[item.name] && post_array.post_meta[item.name] != 0) {

          switch (item.kind) {
            case "checkbox":
              array_feats.push(item.title)
              break
            case "number":
              array_feats.push(item.title + ": " + post_array.post_meta[item.name])
              break
            case "text":
              array_feats.push(item.title + ": " + post_array.post_meta[item.name])
              break
          }

        }

      })
    }

    this.features_text = array_feats.join(", ");

  },
  created() {


  },
  methods: {

  },
};
</script>

<style>
.slider-wrapper,
.details-wrapper {
  height: 100vh;
}

.slider-btn {
  top: 50% !important;
  height: 50px !important;
  transform: translateY(-25px) !important;
}



#lightboxOverlay {
  position: fixed !important;
  top: 0;
  left: 0;
  height: 100% !important;
  width: 100% !important;
}

#lightbox {
  position: fixed !important;
  top: 50% !important;
  transform: translateY(-50%);
}

.lb-nav a.lb-prev,
.lb-nav a.lb-next {
  background-size: 30px !important;
  opacity: 1 !important;
}
.lb-data .lb-close{
    width: 20px !important;
    height: 20px !important;
    background-size: 100% !important;
  }

.lb-details{
  display: none !important
}



@media(max-width: 1199px) {
  .slider-wrapper {
    height: 50vh;
  }

  .details-wrapper {
    height: auto;
    overflow: visible;
  }
  
}
</style>
