<template>
  <div>
    <Breadcrumbs main="Embarcações" title="Embarcação" />



    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <px-card>
            <div slot="with-padding">
              <div class="form theme-form">
                <div class="row">
                  <div class="col-sm-2">
                    <div class="form-group mb-0" v-if="userRole == 'administrator' || this.$route.params.id">
                      <div class="media">
                        <label class="col-form-label m-r-10">Status</label>
                        <div class="media-body text-right icon-state switch-outline">
                          <!-- <label class="switch">
                            <input type="checkbox" name="post_status" id="post_status" value="published"
                              :disabled="!isEditable() && userRole != 'administrator'"
                              :checked="postData['post_status'] === 'published'"
                              @change="() => { postData['post_status'] = postData['post_status'] === 'published' ? 'inactive' : 'published' }"><span
                              class="switch-state bg-success"></span>
                          </label> -->

                          <select :disabled="userRole != 'administrator'" class="form-control" placeholder="Status"
                            v-model="postData['post_status']">
                            <option value="inactive">Desativado</option>
                            <option value="paused">Pausado</option>
                            <option value="published">Ativado</option>
                          </select>




                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-10 text-right">
                    <div class="form-group mb-0">

                      <button v-if="this.$route.params.id" type="button" class="btn btn-light mx-1 my-1"
                        @click="copyLink">{{
                          "Copiar link para compartilhamento"
                        }}</button>

                      <button v-if="this.$route.params.id" type="button" class="btn btn-secondary mx-1 my-1"
                        @click="viewLink">{{ "Ver página de compartilhamento" }}</button>

                      <button v-if="isEditable() || userRole == 'administrator'" type="button"
                        class="btn btn-primary mx-1 my-1" @click="saveData">{{ this.$route.params.id ?
                          "Salvar alterações" :
                          "Cadastrar"
                        }}</button>

                    </div>
                  </div>

                </div>
              </div>
            </div>
          </px-card>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->


    <div class="container-fluid px-0">
      <div class="row">
        <div class="col-sm-9">
          <!-- Container-fluid starts-->
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-12">
                <px-card>
                  <div slot="with-padding">
                    <div class="form theme-form">

                      <div class="row">
                        <div class="col-sm-12 mb-4">
                          <h6 class="sub-title">Identificação</h6>
                        </div>
                      </div>


                      <div class="row">
                        <div class="col-sm-3">
                          <div class="form-group">
                            <label>Fabricante</label>
                            <input class="form-control" type="text" placeholder="Fabricante" name="fabricante"
                              :readonly="!isEditable() && userRole != 'administrator'" v-model="metaData['fabricante']">
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div class="form-group">
                            <label>Modelo</label>
                            <input class="form-control" type="text" placeholder="Modelo" name="modelo"
                              :readonly="!isEditable() && userRole != 'administrator'" v-model="metaData['modelo']">
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div class="form-group">
                            <label>Tamanho (pés)</label>
                            <input class="form-control" type="number" placeholder="Tamanho em pés" name="tamanho"
                              :readonly="!isEditable() && userRole != 'administrator'" v-model="metaData['tamanho']">
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div class="form-group">
                            <label>Ano</label>
                            <input class="form-control" type="number" placeholder="Ano de fabricação" name="ano"
                              :readonly="!isEditable() && userRole != 'administrator'" v-model="metaData['ano']">
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label>Nome da Embarcação *</label>
                            <input class="form-control" type="text" placeholder="Nome" name="post_title"
                              :readonly="!isEditable() && userRole != 'administrator'" v-model="postData['post_title']">
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label>Marina</label>
                            <input class="form-control" type="text" placeholder="Marina" name="marina"
                              :readonly="!isEditable() && userRole != 'administrator'" v-model="metaData['marina']">
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label>Valor</label>
                            <input class="form-control" type="number" placeholder="Valor" name="valor"
                              :readonly="!isEditable() && userRole != 'administrator'" v-model="metaData['valor']">
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </px-card>
              </div>
            </div>
          </div>
          <!-- Container-fluid Ends-->
          <!-- Container-fluid starts-->
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-12">
                <px-card>
                  <div slot="with-padding">






                    <div class="form theme-form">

                      <div class="row">
                        <div class="col-sm-12 mb-4">
                          <h6 class="sub-title">Galeria <b-button v-if="isEditable() || userRole == 'administrator'"
                              v-on:click="removeAllMedia()" id="default-xs-secondary" variant="secondary" size="xs"
                              style="float: right;"><i class="fa fa-trash text-white"></i>
                              Excluir todas as mídias</b-button></h6>
                        </div>
                      </div>



                      <div class="row">



                        <div class="col-sm-2 my-0 mx-0 p-3 square"
                          v-if="($route.params.id || submittedID) && gallery.length > 0 && media"
                          v-for="media in gallery" :key="media">

                          <img v-if="media.includes('.mp4') === false"
                            :src="`https://crm.internauticaguaruja.com.br${media}`" alt=""
                            v-on:click="openMedia(media)" />

                          <video v-if="media.includes('.mp4') === true" preload="metadata" controlsList="nodownload"
                            v-on:click="openMedia(media); return false;">
                            <source :src="`https://crm.internauticaguaruja.com.br${media}`" type="video/mp4">
                            Seu navegador não suporta o elemento de vídeo.
                          </video>

                          <div style="position: absolute; right: 10px; bottom: 10px; padding: 0"
                            v-if="isEditable() || userRole == 'administrator'">
                            <b-button v-on:click="removeMedia(media)" id="default-xs-secondary" variant="secondary"
                              size="xs"><i class="fa fa-trash text-white"></i></b-button>
                          </div>
                        </div>



                      </div>



                      <div class="row mt-3" v-if="isEditable() || userRole == 'administrator'">
                        <div class="col">
                          <div class="form-group">
                            <label>Upload</label>


                            <vue-dropzone ref="myVueDropzone" :options="dropzoneOptions" id="dropzone"
                              class="dropzone digits" v-on:vdropzone-sending-multiple="sendingEvent"
                              v-on:vdropzone-success-multiple="successMethod"></vue-dropzone>


                          </div>
                          <!-- <div class="form-group" v-if="!this.$route.params.id">
                            <small>Cadastre o produto para poder enviar fotos.</small>
                          </div> -->
                        </div>
                      </div>
                    </div>





                  </div>
                </px-card>
              </div>
            </div>
          </div>
          <!-- Container-fluid Ends-->
          <!-- Container-fluid starts-->
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-12">
                <px-card>
                  <div slot="with-padding">
                    <div class="form theme-form">

                      <div class="row">
                        <div class="col-sm-12 mb-4">
                          <h6 class="sub-title">Motorização</h6>
                        </div>
                      </div>


                      <div class="row">
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label>Marca</label>
                            <input class="form-control" type="text" placeholder="Marca" name="motorizacao-marca"
                              :readonly="!isEditable() && userRole != 'administrator'"
                              v-model="metaData['motorizacao-marca']">
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label>Quantidade</label>
                            <input class="form-control" type="number" placeholder="Quantidade"
                              :readonly="!isEditable() && userRole != 'administrator'" name="motorizacao-quantidade"
                              v-model="metaData['motorizacao-quantidade']">
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label>Modelo / Potência</label>
                            <input class="form-control" type="text" placeholder="Modelo / Potência"
                              :readonly="!isEditable() && userRole != 'administrator'"
                              name="motorizacao-modelo-potencia" v-model="metaData['motorizacao-modelo-potencia']">
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label>Ano</label>
                            <input class="form-control" type="number" placeholder="Ano" name="motorizacao-ano"
                              :readonly="!isEditable() && userRole != 'administrator'"
                              v-model="metaData['motorizacao-ano']">
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label>Horas</label>
                            <input class="form-control" type="number" placeholder="Horas" name="motorizacao-horas"
                              :readonly="!isEditable() && userRole != 'administrator'"
                              v-model="metaData['motorizacao-horas']">
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label>Combustível</label>
                            <input class="form-control" type="text" placeholder="Combustível"
                              :readonly="!isEditable() && userRole != 'administrator'" name="motorizacao-combustivel"
                              v-model="metaData['motorizacao-combustivel']">
                          </div>
                        </div>
                      </div>


                      <div class="row">
                        <div class="col-sm-3">
                          <div class="form-group m-checkbox-inline mb-0 custom-radio-ml">
                            <div class="checkbox checkbox-dark">
                              <b-form-checkbox name="motorizacao-superficie"
                                v-model="metaData['motorizacao-superficie']"
                                :disabled="!isEditable() && userRole != 'administrator'" value="1">
                                Superfície
                              </b-form-checkbox>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div class="form-group m-checkbox-inline mb-0 custom-radio-ml">
                            <div class="checkbox checkbox-dark">
                              <b-form-checkbox name="motorizacao-ips"
                                :disabled="!isEditable() && userRole != 'administrator'"
                                v-model="metaData['motorizacao-ips']" value="1">
                                IPS
                              </b-form-checkbox>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div class="form-group m-checkbox-inline mb-0 custom-radio-ml">
                            <div class="checkbox checkbox-dark">
                              <b-form-checkbox name="motorizacao-pe-de-galinha"
                                :disabled="!isEditable() && userRole != 'administrator'"
                                v-model="metaData['motorizacao-pe-de-galinha']" value="1">Pé-de-Galinha
                              </b-form-checkbox>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div class="form-group m-checkbox-inline mb-0 custom-radio-ml">
                            <div class="checkbox checkbox-dark">
                              <b-form-checkbox name="motorizacao-popa" v-model="metaData['motorizacao-popa']" value="1"
                                :disabled="!isEditable() && userRole != 'administrator'">
                                Popa</b-form-checkbox>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-3" style="flex-direction: row; display: flex;">
                          <div class="form-group m-checkbox-inline mb-0 custom-radio-ml"
                            style="display: flex; flex: .5;">
                            <div class="checkbox checkbox-dark">
                              <b-form-checkbox name="motorizacao-rabeta" v-model="metaData['motorizacao-rabeta']"
                                :disabled="!isEditable() && userRole != 'administrator'" value="1">
                                Rabeta</b-form-checkbox>

                            </div>

                          </div>

                          <div class="form-group mb-0" style="display: flex;flex: .5;">

                            <input class="form-control" type="text" placeholder="Rabeta" name="motorizacao-rabeta-text"
                              :readonly="!isEditable() && userRole != 'administrator'"
                              v-model="metaData['motorizacao-rabeta-text']">
                          </div>




                        </div>

                      </div>


                    </div>
                  </div>
                </px-card>
              </div>
            </div>
          </div>
          <!-- Container-fluid Ends-->

          <!-- Container-fluid starts-->
          <!-- <div class="container-fluid">
            <div class="row">
              <div class="col-sm-12">
                <px-card>
                  <div slot="with-padding">
                    <div class="form theme-form">

                      <div class="row">
                        <div class="col-sm-12 mb-4">
                          <h6 class="sub-title">Estado Geral</h6>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label>Pintura %</label>
                            <input class="form-control" type="number" placeholder="Pintura %" name="estado-pintura"
                              :readonly="!isEditable() && userRole != 'administrator'"
                              v-model="metaData['estado-pintura']">
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label>Interior %</label>
                            <input class="form-control" type="number" placeholder="Interior %" name="estado-interior"
                              :readonly="!isEditable() && userRole != 'administrator'"
                              v-model="metaData['estado-interior']">
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label>Casa de Máquinas %</label>
                            <input class="form-control" type="number" placeholder="Casa de Máquinas %"
                              :readonly="!isEditable() && userRole != 'administrator'"
                              name="estado-casa-de-maquinas" v-model="metaData['estado-casa-de-maquinas']">
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-6">
                          <div class="form-group">
                            <label>Cores</label>
                            <input class="form-control" type="text" placeholder="Cores" name="estado-cores"
                              :readonly="!isEditable() && userRole != 'administrator'"
                              v-model="metaData['estado-cores']">
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="form-group">
                            <label>Fundo</label>
                            <input class="form-control" type="text" placeholder="Fundo" name="estado-fundo"
                              :readonly="!isEditable() && userRole != 'administrator'"
                              v-model="metaData['estado-fundo']">
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </px-card>
              </div>
            </div>
          </div> -->
          <!-- Container-fluid Ends-->
          <!-- Container-fluid starts-->
          <!-- <div class="container-fluid">
            <div class="row">
              <div class="col-sm-12">
                <px-card>
                  <div slot="with-padding">
                    <div class="form theme-form">

                      <div class="row">
                        <div class="col-sm-12 mb-4">
                          <h6 class="sub-title">Documentação</h6>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-4">
                          <div class="form-group m-checkbox-inline mb-0 custom-radio-ml">
                            <div class="checkbox checkbox-dark">
                              <b-form-checkbox name="doc-titulo-embarcacao" v-model="metaData['doc-titulo-embarcacao']"
                                :disabled="!isEditable() && userRole != 'administrator'" value="1">
                                Titulo da Embarcação
                              </b-form-checkbox>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group m-checkbox-inline mb-0 custom-radio-ml">
                            <div class="checkbox checkbox-dark">
                              <b-form-checkbox name="doc-termo-de-responsabilidade"
                                :disabled="!isEditable() && userRole != 'administrator'"
                                v-model="metaData['doc-termo-de-responsabilidade']" value="1">Termo de
                                Responsabilidade
                              </b-form-checkbox>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group m-checkbox-inline mb-0 custom-radio-ml">
                            <div class="checkbox checkbox-dark">
                              <b-form-checkbox name="doc-seguro-obrigatorio"
                                :disabled="!isEditable() && userRole != 'administrator'"
                                v-model="metaData['doc-seguro-obrigatorio']" value="1">Seguro Obrigatório
                              </b-form-checkbox>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group m-checkbox-inline mb-0 custom-radio-ml">
                            <div class="checkbox checkbox-dark">
                              <b-form-checkbox name="doc-seguro-casco" v-model="metaData['doc-seguro-casco']" value="1"
                                :disabled="!isEditable() && userRole != 'administrator'">
                                Seguro do
                                Casco</b-form-checkbox>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group m-checkbox-inline mb-0 custom-radio-ml">
                            <div class="checkbox checkbox-dark">
                              <b-form-checkbox name="doc-recibo-de-venda" v-model="metaData['doc-recibo-de-venda']"
                                :disabled="!isEditable() && userRole != 'administrator'" value="1">
                                Recibo de Venda</b-form-checkbox>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </px-card>
              </div>
            </div>
          </div> -->
          <!-- Container-fluid Ends-->
          <!-- Container-fluid starts-->
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-12">
                <px-card>
                  <div slot="with-padding">
                    <div class="form theme-form">

                      <div class="row">
                        <div class="col-sm-12 mb-4">
                          <h6 class="sub-title">Captação</h6>
                        </div>
                      </div>



                      <!-- <div class="row">
                        <div class="col-sm-12">
                          <div class="form-group">


                            <label>
                              <span>Captador</span> - <b-link class="text-success" @click="callPostAuthor()">
                                <i class="fa fa-whatsapp"></i> Entrar em contato
                              </b-link></label>

                            <select :disabled="userRole != 'administrator'" class="form-control" placeholder="Captador"
                              v-model="postData['post_author']">
                              <option value="">-</option>
                              <option v-for="user in this.usersList" :key="'user-' + user.id" :value="user.id">{{
                                user.user_name
                              }}
                              </option>
                            </select>

                          </div>
                        </div>
                      </div> -->


                      <div class="row">
                        <div class="col-sm-12">
                          <div class="form-group">


                            <label>
                              <span>Captadores</span></label>


                            <div class="row my-2" v-for="(captador, c) in captadoresList">
                              <div class="col-sm-12" style="display: flex;">

                                <select style="flex: 1" :disabled="!isEditable()" class="form-control"
                                  placeholder="Captador" v-bind:value="postData ? captador : userID" @change="(e) => {
                                    log(e.target.value)
                                    if (isEditable()) {
                                      var newCaptadoresList = [...captadoresList]
                                      newCaptadoresList[c] = e.target.value
                                      captadoresList = [...newCaptadoresList];
                                    }
                                  }">
                                  <option value="">-</option>

                                  <option v-for="user in usersList" :key="'user-' + user.id" :value="user.id">{{
                                    user.user_name
                                    }}
                                  </option>
                                </select>


                                <b-button v-if="captador" v-on:click="callCaptador(captador)" variant="dark" size="l"
                                  style="background-color: #eee; margin-left: 5px; "><i
                                    class="fa fa-whatsapp text-white"></i>
                                </b-button>




                                <b-button v-if="c > 0 && isEditable()" v-on:click="() => {

                                  var newCaptadoresList = [...captadoresList];
                                  newCaptadoresList.splice(c, 1)
                                  captadoresList = [...newCaptadoresList]

                                }" variant="dark" size="l" style="background-color: #eee; margin-left: 5px;"><i
                                    class="fa fa-minus text-white"></i>
                                </b-button>




                                <b-button v-if="c === captadoresList.length - 1 && isEditable()" v-on:click="() => {

                                  captadoresList = [...captadoresList, null]

                                }" variant="dark" size="l" style="background-color: #eee; margin-left: 5px;"><i
                                    class="fa fa-plus text-white"></i>
                                </b-button>

                              </div>







                            </div>









                          </div>
                        </div>
                      </div>



                      <div class="row" v-if="isEditable() || userRole == 'administrator'">
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label>Marinheiro</label>
                            <input class="form-control" type="text" placeholder="Marinheiro" name="captacao-marinheiro"
                              v-model="metaData['captacao-marinheiro']">
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label>Tel. Marinheiro:</label>
                            <input class="form-control" type="text" placeholder="Tel. Marinheiro"
                              name="captacao-marinheiro-tel" v-model="metaData['captacao-marinheiro-tel']">
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label>Email Marinheiro:</label>
                            <input class="form-control" type="text" placeholder="Email Marinheiro"
                              name="captacao-marinheiro-email" v-model="metaData['captacao-marinheiro-email']">
                          </div>
                        </div>
                      </div>


                      <div class="row" v-if="isEditable() || userRole == 'administrator'">
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label>Proprietário *</label>
                            <input class="form-control" type="text" placeholder="Proprietário"
                              name="captacao-proprietario" v-model="metaData['captacao-proprietario']">
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label>Tel. Proprietário: *</label>
                            <input class="form-control" type="text" placeholder="Tel. Proprietário"
                              name="captacao-proprietario-tel" v-model="metaData['captacao-proprietario-tel']">
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label>Email Proprietário:</label>
                            <input class="form-control" type="text" placeholder="Email Proprietário"
                              name="captacao-proprietario-email" v-model="metaData['captacao-proprietario-email']">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </px-card>
              </div>
            </div>
          </div>
          <!-- Container-fluid Ends-->
          <!-- Container-fluid starts-->
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-12">
                <px-card>
                  <div slot="with-padding">
                    <div class="form theme-form">

                      <div class="row">
                        <div class="col-sm-12 mb-4">
                          <h6 class="sub-title">Anunciar em</h6>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-4">
                          <div class="form-group m-checkbox-inline mb-0 custom-radio-ml"
                            style="display: flex; align-items: center;">
                            <div class="checkbox checkbox-dark">
                              <b-form-checkbox name="anuncio-site" v-model="metaData['anuncio-site']"
                                :disabled="userRole != 'administrator'" value="1">
                                Site
                              </b-form-checkbox>
                            </div>
                            <a v-if="metaData['anuncio-site']"
                              :href="'https://internauticayachts.com.br/embarcacao/' + this.$route.params.id + '/' + this.postData['post_name'] + '/'"
                              target="_blank"><small>Link</small></a>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group m-checkbox-inline mb-0 custom-radio-ml"
                            style="display: flex; align-items: center;">
                            <div class="checkbox checkbox-dark">
                              <b-form-checkbox name="anuncio-mercado-livre" :disabled="userRole != 'administrator'"
                                v-model="metaData['anuncio-mercado-livre']" value="1">Mercado Livre
                              </b-form-checkbox>
                            </div>
                            <a v-if="metaData['anuncio-mercado-livre-mlb-id'] && userRole == 'administrator'"
                              :href="'https://www.mercadolivre.com.br/anuncios/lista?search=' + metaData['anuncio-mercado-livre-mlb-id']"
                              target="_blank"><small>Link</small></a>
                          </div>
                        </div>
                      </div>


                      <div class="row">
                        <div class="col-sm-12 mb-4 mt-5">
                          <h6 class="sub-title">Outros anúncios</h6>
                        </div>
                      </div>


                      <div class="row">
                        <div class="col-sm-4">
                          <div class="form-group m-checkbox-inline mb-0 custom-radio-ml">
                            <div class="checkbox checkbox-dark">
                              <b-form-checkbox name="anuncio-bombarco" :disabled="userRole != 'administrator'"
                                v-model="metaData['anuncio-bombarco']" value="1">Bombarco
                              </b-form-checkbox>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group m-checkbox-inline mb-0 custom-radio-ml">
                            <div class="checkbox checkbox-dark">
                              <b-form-checkbox name="anuncio-instagram" v-model="metaData['anuncio-instagram']"
                                value="1" :disabled="userRole != 'administrator'">
                                Instagram</b-form-checkbox>
                            </div>
                          </div>
                        </div>
                      </div>


                    </div>
                  </div>
                </px-card>
              </div>
            </div>
          </div>
          <!-- Container-fluid Ends-->

          <!-- Container-fluid starts-->
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-12">
                <px-card>
                  <div slot="with-padding">
                    <div class="form theme-form">

                      <div class="row">
                        <div class="col-sm-12 mb-4">
                          <h6 class="sub-title">Informações extras</h6>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col">
                          <div class="form-group">
                            <label>Observações
                              <span style="background-color: rgb(248, 219, 102); padding: 0 5px; border-radius: 4px;">
                                administrativas </span>
                            </label>
                            <textarea class="form-control" id="exampleFormControlTextarea4" rows="5" name="observacoes"
                              :readonly="!isEditable() && userRole != 'administrator'"
                              v-model="metaData['observacoes']"></textarea>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col">
                          <div class="form-group">
                            <label>Observações <span
                                style="background-color: #65b7e7; padding: 0 5px; border-radius: 4px;"> públicas
                              </span></label>
                            <textarea class="form-control" id="exampleFormControlTextarea4" rows="5"
                              name="observacoes_publicas" :readonly="!isEditable() && userRole != 'administrator'"
                              v-model="metaData['observacoes_publicas']"></textarea>
                          </div>
                        </div>
                      </div>



                      <div class="row">
                        <div class="col">
                          <div class="form-group">
                            <label>Palavras-chave relacionadas</label>



                            <multiselect id="inputKeywords" v-model="selectedKeywords"
                              :disabled="!isEditable() && userRole != 'administrator'"
                              placeholder="Selecione palavras-chave relacionadas"
                              tag-placeholder="Adicionar como palavra-chave"
                              :options="keywords.map((keyword) => keyword)" :taggable="true" :multiple="true"
                              :show-labels="false" :max-height="200" @input="onKeywordSelect" @tag="addTag">

                              <template slot="noOptions" slot-scope="props">Lista vazia.</template>

                            </multiselect>
                          </div>
                        </div>
                      </div>






                    </div>
                  </div>
                </px-card>
              </div>
            </div>
          </div>
          <!-- Container-fluid Ends-->

          <!-- Container-fluid starts-->
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-12">
                <px-card>
                  <div slot="with-padding">
                    <div class="form theme-form">

                      <div class="row mb-4">
                        <div class="col-sm-12">
                          <h6 class="sub-title">Histórico</h6>
                        </div>
                      </div>


                      <div class="table-responsive mb-0" style="max-height: 256px;">
                        <b-table class="table-striped mb-0" style="font-size: 12px" :items="postLogs"
                          :fields="logFields" responsive="sm">

                          <template v-slot:cell(log_user)="{ item }">
                            <span v-if="item.user_data['user_name']">{{ item.user_data['user_name'] }}</span>
                          </template>


                          <template v-slot:cell(log_action)="{ item }">
                            <span v-if="item.log_action === 'UPDATED'">Atualização</span>
                            <span v-if="item.log_action === 'INSERTED'">Cadastro</span>
                            <span v-if="item.log_action === 'DELETED'">Exclusão</span>
                          </template>




                        </b-table>
                      </div>


                    </div>
                  </div>
                </px-card>
              </div>
            </div>
          </div>
          <!-- Container-fluid Ends-->


        </div>

        <div class="col-sm-3">
          <!-- Container-fluid starts-->
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-12">
                <px-card>
                  <div slot="with-padding">


                    <div class="form theme-form">

                      <div class="row">
                        <div class="col-sm-12 mb-4">
                          <h6 class="sub-title">Características e Equipamentos</h6>
                        </div>
                      </div>

                      <div class="row mb-3">
                        <div class="col-sm-12">
                          Selecionar <a href="#" v-on:click.prevent.stop="selectAllFeatures();">tudo</a> |
                          <a href="#" v-on:click.prevent.stop="selectNoneFeatures();">nada</a>
                        </div>
                      </div>

                      <div class="row">

                        <div class="col-sm-12 " v-for="feature in features" :key="feature.name">
                          <div v-if="feature.kind === 'checkbox'"
                            class="form-group m-checkbox-inline mb-0 custom-radio-ml">
                            <div class="checkbox checkbox-dark">
                              <b-form-checkbox :name="feature.name" v-model="metaData[feature.name]" value="1"
                                :disabled="!isEditable() && userRole != 'administrator'">
                                {{ feature.title }}</b-form-checkbox>
                            </div>
                          </div>

                          <div v-if="feature.kind === 'number'" class="form-group mb-0 custom-radio-ml">

                            <label>
                              <input class="form-control ml-2 mr-2 m-t-5 hide-arrows" type="number" placeholder=""
                                :readonly="!isEditable() && userRole != 'administrator'" :name="feature.name"
                                v-model="metaData[feature.name]"
                                style="width: 68px; height: 30px; display: inline-block; border-color: #333; ">
                              {{ feature.title }}
                            </label>

                          </div>

                          <div v-if="feature.kind === 'text'" class="form-group mb-0 custom-radio-ml">

                            <label>
                              <input class="form-control ml-2 mr-2 m-t-5" type="text" placeholder=""
                                :readonly="!isEditable() && userRole != 'administrator'" :name="feature.name"
                                v-model="metaData[feature.name]"
                                style="width: 68px; height: 30px; display: inline-block; border-color: #333; ">
                              {{ feature.title }}
                            </label>

                          </div>


                        </div>

                      </div>
                    </div>



                  </div>
                </px-card>
              </div>
            </div>
          </div>
          <!-- Container-fluid Ends-->

        </div>
      </div>
    </div>



  </div>
</template>
<script>


import { getVessel, updateVessel, createVessel, deleteMedia, deleteAllMedia } from '../../data/vessels'

import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Multiselect from 'vue-multiselect'

import { listUsers } from '../../data/users'
import _ from 'lodash'

import moment from 'moment'


export default {
  components: {
    vueDropzone: vue2Dropzone,
    Multiselect
  },
  data() {
    return {
      userID: localStorage.getItem("user_id"),
      userRole: localStorage.getItem('user_role'),

      submittedID: null,


      usersList: [

      ],

      keywords: [

      ],
      selectedKeywords: [

      ],

      authorData: {

      },

      postData: {

      },
      metaData: {

      },
      gallery: [],

      postLogs: [],


      captadoresList: [],
      editors: [],



      originalSelectedKeywords: [

      ],
      originalAuthorData: {

      },
      originalPostData: {

      },
      originalMetaData: {

      },
      originalGallery: [

      ],


      logFields: [
        { key: 'log_date', label: 'Data', sortable: false, formatter: this.formatDate },
        { key: 'log_user', label: 'Usuário', sortable: false },
        { key: 'log_action', label: 'Ação', sortable: false },
      ],



      features: [
        { name: 'feat-antena-tv', title: 'Antena TV', kind: 'checkbox' },
        { name: 'feat-ar-condicionado', title: 'Ar Condicionado', kind: 'checkbox' },
        { name: 'feat-boiler', title: 'Boiler', kind: 'checkbox' },
        { name: 'feat-bowthruster', title: 'Bowthruster', kind: 'checkbox' },
        { name: 'feat-bussola', title: 'Bússola', kind: 'checkbox' },
        { name: 'feat-capa', title: 'Capa', kind: 'checkbox' },
        { name: 'feat-capota', title: 'Capota', kind: 'checkbox' },
        { name: 'feat-carregador-de-baterias', title: 'Carregador de Baterias', kind: 'checkbox' },
        { name: 'feat-carreta', title: 'Carreta', kind: 'checkbox' },
        { name: 'feat-churrasqueira', title: 'Churrasqueira', kind: 'checkbox' },
        { name: 'feat-chuveiro-de-popa', title: 'Chuveiro de Popa', kind: 'checkbox' },
        { name: 'feat-cristaleira', title: 'Cristaleira', kind: 'checkbox' },
        { name: 'feat-comando-eletronico', title: 'Comando Eletrônico', kind: 'checkbox' },
        { name: 'feat-dessalinizador', title: 'Dessalinizador', kind: 'checkbox' },
        { name: 'feat-direcao-hidraulica', title: 'Direção Hidráulica', kind: 'checkbox' },
        { name: 'feat-dvd', title: 'DVD', kind: 'checkbox' },

        { name: 'feat-estabilizador', title: 'Estabilizador', kind: 'checkbox' },
        { name: 'feat-farois', title: 'Faróis', kind: 'checkbox' },
        { name: 'feat-farol-robotizado', title: 'Farol Robotizado', kind: 'checkbox' },
        { name: 'feat-fechamento-completo', title: 'Fechamento Completo', kind: 'checkbox' },
        { name: 'feat-flaps-hidraulicos', title: 'Flaps Hidráulicos', kind: 'checkbox' },
        { name: 'feat-fogao-eletrico', title: 'Fogão Elétrico', kind: 'checkbox' },
        { name: 'feat-freezer', title: 'Freezer', kind: 'checkbox' },
        { name: 'feat-geladeira', title: 'Geladeira', kind: 'checkbox' },
        { name: 'feat-geleira', title: 'Geleira', kind: 'checkbox' },
        { name: 'feat-gerador', title: 'Gerador', kind: 'checkbox' },
        { name: 'feat-gerador-kva', title: 'Gerador KVA', kind: 'text' },
        { name: 'feat-gerador-horas', title: 'Gerador Horas', kind: 'number' },
        { name: 'feat-gps', title: 'GPS', kind: 'checkbox' },
        { name: 'feat-gps-fly', title: 'GPS Fly', kind: 'checkbox' },
        { name: 'feat-guincho-eletrico', title: 'Guincho Elétrico', kind: 'checkbox' },
        { name: 'feat-home-theater', title: 'Home Theater', kind: 'checkbox' },
        { name: 'feat-icemaker', title: 'Icemaker', kind: 'checkbox' },

        { name: 'feat-iluminacao-subaquatica', title: 'Iluminação Subaquática', kind: 'checkbox' },
        { name: 'feat-inversor', title: 'Inversor', kind: 'checkbox' },
        { name: 'feat-joystick', title: 'Joystick', kind: 'checkbox' },
        { name: 'feat-plataforma-submergivel', title: 'Plataforma Submergível', kind: 'checkbox' },
        { name: 'feat-microondas', title: 'Microondas', kind: 'checkbox' },
        { name: 'feat-passarela-hidraulica', title: 'Passarela Hidráulica', kind: 'checkbox' },
        { name: 'feat-piloto-automatico', title: 'Piloto Automático', kind: 'checkbox' },
        { name: 'feat-plotter', title: 'Plotter', kind: 'checkbox' },
        { name: 'feat-projetor-telao', title: 'Projetor / Telão', kind: 'checkbox' },
        { name: 'feat-radar', title: 'Radar', kind: 'checkbox' },
        { name: 'feat-radio-vhf', title: 'Rádio VHF', kind: 'checkbox' },
        { name: 'feat-radio-vhf-fly', title: 'Rádio VHF Fly', kind: 'checkbox' },
        { name: 'feat-sky', title: 'SKY', kind: 'checkbox' },
        { name: 'feat-solario-de-popa', title: 'Solário de Popa', kind: 'checkbox' },
        { name: 'feat-solario-de-proa', title: 'Solário de Proa', kind: 'checkbox' },
        { name: 'feat-som', title: 'Som', kind: 'checkbox' },

        { name: 'feat-sonda', title: 'Sonda', kind: 'checkbox' },
        { name: 'feat-strobolight', title: 'Strobolight', kind: 'checkbox' },
        { name: 'feat-tapete-emborrachado', title: 'Tapete Emborrachado', kind: 'checkbox' },
        { name: 'feat-targa', title: 'Targa', kind: 'checkbox' },
        { name: 'feat-teka-patamar-de-popa', title: 'Teka Patamar de Popa', kind: 'checkbox' },
        { name: 'feat-teka-praca-de-popa', title: 'Teka Praça de Popa', kind: 'checkbox' },
        { name: 'feat-teka-cockpit', title: 'Teka Cockpit', kind: 'checkbox' },
        { name: 'feat-teka-passadico', title: 'Teka Passadiço', kind: 'checkbox' },
        { name: 'feat-teka-proa', title: 'Teka Proa', kind: 'checkbox' },
        { name: 'feat-televisao', title: 'Televisão', kind: 'checkbox' },
        { name: 'feat-toldo', title: 'Toldo', kind: 'checkbox' },
        { name: 'feat-tomada-de-cais', title: 'Tomada de Cais', kind: 'checkbox' },
        { name: 'feat-turco-na-popa', title: 'Turco na Popa', kind: 'checkbox' },
        { name: 'feat-ventilador', title: 'Ventilador', kind: 'checkbox' },
        { name: 'feat-wc-eletrico', title: 'WC Elétrico', kind: 'checkbox' },
        { name: 'feat-wc-manual', title: 'WC Manual', kind: 'checkbox' },

        { name: 'feat-defensa', title: 'Defensa', kind: 'checkbox' },
        { name: 'feat-extintor', title: 'Extintor', kind: 'checkbox' },

        { name: 'feat-quartos', title: 'Quartos', kind: 'number' },
        { name: 'feat-banheiros', title: 'Banheiros', kind: 'number' },
      ],
      dropzoneOptions: {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("user_jwt")}`,
          //'Content-type': 'multipart/form-data'
        },
        autoProcessQueue: false,
        url: "https://crm.internauticaguaruja.com.br/api/upload.file.php",
        thumbnailWidth: 150,
        addRemoveLinks: true,
        dictDefaultMessage: '<i class=\'icon-cloud-up\'></i><p><small>Arraste ou clique para enviar.</small></p>',
        acceptedFiles: 'image/*,video/mp4',
        maxFiles: 50,
        timeout: 0,
        maxFilesize: 50,
        uploadMultiple: true,
        parallelUploads: 10,
        resizeQuality: 0.9,
        resizeWidth: 1920,
        resizeHeight: 1920,
      }
    };
  },
  async mounted() {

    this.loader = this.$loading.show()
    this.loaded = false

    await this.getUsersList()
    if (this.$route.params.id) {
      this.loadData()
    }
    else {
      this.postData = { post_author: this.userID }
      this.captadoresList = [this.userID]
    }
  },
  computed: {


  },
  watch: {
    // whenever question changes, this function will run
    /* metaData(newVal, oldVal) {
    } */
    // metaData: { immediate: true }
  },

  methods: {

    log(msg) {
      console.log(msg);
    },

    isEditable() {
      return !this.$route.params.id || this.editors.includes(parseInt(this.userID)) || this.userRole === 'administrator'
    },

    callCaptador(id) {
      const index = this.usersList.map(object => object.id).indexOf(id);

      if (this.usersList[index]['user_meta']['telefone']) {
        window.open('https://wa.me/' + this.usersList[index]['user_meta']['telefone'])
      }
      else {
        this.$toasted.show(' Telefone do captador não encontrado ', { theme: 'outline', position: 'top-right', type: 'warning', icon: 'check', duration: 5000 })
      }
    },

    async getUsersList() {
      this.usersList = await listUsers()

      if (!this.$route.params.id) {
        this.loaded = true
        setTimeout(() => {
          this.loader.hide()
        }, 500)
      }

    },

    storeOriginals() {
      this.originalPostData = Object.create(this.postData);
      this.originalMetaData = Object.create(this.metaData);
      this.originalAuthorData = Object.create(this.authorData);
      this.originalGallery = [...this.gallery];
      this.originalSelectedKeywords = [...this.selectedKeywords];
    },


    async loadData() {

      this.loaded = false

      this.gallery = []

      let post = await getVessel(this.$route.params.id)


      if (!post || !post.id) {
        this.$router.push({ path: `/404` })
        return
      }


      this.postData = {
        id: post.id,
        post_title: post.post_title,
        post_author: post.post_author,
        post_status: post.post_status,
        post_name: post.post_name,
        post_type: post.post_type,
      }


      this.postLogs = post.log_data


      this.authorData = post.author_data


      //this.metaData =  Object.fromEntries(post.post_meta) 
      ///this.metaData = JSON.parse(JSON.stringify(post.post_meta))
      this.metaData = post.post_meta

      if (this.metaData['medias']) {
        this.gallery = this.metaData['medias'].split(';')
      }

      if (this.metaData['keywords']) {
        this.selectedKeywords = this.metaData['keywords'].split(';')
      }

      if (this.metaData['captacao-captadores']) {
        this.selectedCaptadores = this.metaData['captacao-captadores'].split(';')
      }

      this.post = post

      this.captadoresList = post.post_meta['captadores'] ? post.post_meta['captadores'].split(';').map(Number) : [post.post_author]
      this.editors = post.post_meta['captadores'] ? post.post_meta['captadores'].split(';').map(Number) : [post.post_author]


      this.storeOriginals()


      this.loaded = true
      setTimeout(() => {
        this.loader.hide()
      }, 500)
    },
    async saveData() {
      if (this.postData['post_title'] && this.postData['post_title'].trim() &&
        this.metaData['captacao-proprietario'] && this.metaData['captacao-proprietario'].trim() &&
        this.metaData['captacao-proprietario-tel'] && this.metaData['captacao-proprietario-tel'].trim()
      ) {
        this.loader = this.$loading.show()
        this.loaded = false
        //this.loadData()


        //this.postData['post_status'] = this.postData['post_status'] === 'published' ? 'published' : 'inactive';
        this.postData['post_status'] = this.postData['post_status'] ? this.postData['post_status'] : 'pending';


        this.metaData['keywords'] = this.selectedKeywords.join(';')


        var filteredCaptadoresList = this.captadoresList.filter(function (el) {
          return el != null;
        });
        this.metaData['captadores'] = filteredCaptadoresList.join(";")


        if (this.$route.params.id) {


          /* var diff = _.reduce(a, function (result, value, key) {
            return _.isEqual(value, b[key]) ?
              result : result.concat(key);
          }, []); */


          var res = await updateVessel(this.$route.params.id, this.postData, this.metaData);
          if (res > 0) {

            this.submittedID = this.$route.params.id;




            if (this.$refs.myVueDropzone.getQueuedFiles().length > 0) {
              this.$refs.myVueDropzone.processQueue();
            }
            else {
              this.$toasted.show(' Salvo com sucesso ', { theme: 'outline', position: 'top-right', type: 'success', icon: 'check', duration: 5000 });
              setTimeout(() => {
                this.loader.hide()
              }, 500)
              this.loadData();
            }

          }
        }
        else {


          var notify = false;

          if (this.userRole !== 'administrator') {
            this.postData['post_status'] = 'pending'
            notify = true;
          }

          var res = await createVessel(this.postData, this.metaData, notify);
          if (res > 0) {

            this.submittedID = res;



            if (this.$refs.myVueDropzone.getQueuedFiles().length > 0) {
              this.$refs.myVueDropzone.processQueue();
            }
            else {
              this.$toasted.show(' Salvo com sucesso ', { theme: 'outline', position: 'top-right', type: 'success', icon: 'check', duration: 5000 });
              this.$router.push({ path: `/vessels/${res}` })
              setTimeout(() => {
                this.loader.hide()
              }, 500)
            }



          }
        }

      } else {
        this.$toasted.show(' Por favor, preencha os campos obrigatórios ', { theme: 'outline', position: 'top-right', type: 'danger', icon: 'info', duration: 5000 });
      }
    },
    sendingEvent(files, xhr, formData) {
      formData.append("user", localStorage.getItem("user_id"))
      formData.append("id", this.submittedID)
      formData.append("kind", 'posts')
      formData.append("post_type", 'vessels')
    },
    successMethod(files, response) {



      if (this.$refs.myVueDropzone.getQueuedFiles().length > 0) {
        this.$refs.myVueDropzone.processQueue();
      }
      else {
        if (response && response.medias > 0) {
          this.$refs.myVueDropzone.removeAllFiles();
          this.$toasted.show(' Salvo com sucesso ', { theme: 'outline', position: 'top-right', type: 'success', icon: 'check', duration: 5000 })
          //this.$router.push({ path: `/vessels/${this.submittedID}` })

          if (this.$route.params.id) {
            this.loadData()
          } else {
            this.$router.push({ path: `/vessels/${this.submittedID}` })
          }
        }
        setTimeout(() => {
          this.loader.hide()
        }, 500)
      }




    },
    async removeMedia(media) {



      if (confirm("Tem certeza que deseja remover esta mídia? Essa ação não pode ser desfeita.") == true) {
        this.loader = this.$loading.show()
        this.loaded = false
        var res = await deleteMedia(this.$route.params.id, media)


        if (res['medias'] < this.gallery.length) {
          this.$toasted.show(' Mídia excluída com sucesso ', { theme: 'outline', position: 'top-right', type: 'success', icon: 'check', duration: 5000 })
          this.loadData()
        } else {
          setTimeout(() => {
            this.loader.hide()
          }, 500)
        }
      }


    }
    ,
    async removeAllMedia() {

      if (this.isEditable() || this.userRole == 'administrator') {
        if (confirm("Tem certeza que deseja remover todas as mídias deste cadastro? Essa ação não pode ser desfeita.") == true) {
          this.loader = this.$loading.show()
          this.loaded = false
          var res = await deleteAllMedia(this.$route.params.id)


          if (res['medias'] === this.gallery.length) {
            this.$toasted.show(' Todas as mídias foram excluídas com sucesso ', { theme: 'outline', position: 'top-right', type: 'success', icon: 'check', duration: 5000 })
            this.loadData()
          } else {
            setTimeout(() => {
              this.loader.hide()
            }, 500)
          }
        }
      }




    },
    copyLink() {
      navigator.clipboard.writeText('https://crm.internauticaguaruja.com.br/v/' + this.$route.params.id + '/' + this.postData["post_name"]);
      this.$toasted.show(' Link copiado com sucesso ', { theme: 'outline', position: 'top-right', type: 'success', icon: 'check', duration: 5000 })
    },
    viewLink() {
      window.open('https://crm.internauticaguaruja.com.br/v/' + this.$route.params.id + '/' + this.postData["post_name"], '_blank');
    },
    openMedia(media) {
      window.open('https://crm.internauticaguaruja.com.br' + media);
    },
    onKeywordSelect() {
    },
    addTag(newTag) {
      this.keywords.push(newTag)
      this.selectedKeywords.push(newTag)
    },
    selectAllFeatures() {




      this.features.forEach((feature) => {

        if (feature.kind === 'checkbox')
          this.metaData[feature.name] = 1;

      })



      this.metaData = { ...this.metaData }

    },
    selectNoneFeatures() {

      this.features.forEach((feature) => {

        if (feature.kind === 'checkbox')
          this.metaData[feature.name] = '';

      })

      this.metaData = { ...this.metaData }


    },
    formatDate(value) {


      try {
        return moment(value).format('DD/MM/YYYY @ H:mm')
        //return moment(value).format('DD/MM/YY')
      } catch (e) {
        return null
      }
    },
  }
};
</script>




















































































<style src="vue-multiselect/dist/vue-multiselect.min.css" />


<style>
.square img,
.square video {
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  border-radius: 10px;
  cursor: pointer;
}

.square:hover:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, .2);
  pointer-events: none;
}
</style>